import { useEffect, useState } from 'react';
import './Step.scss';

function StepFive({
    onSubmit,
    setDisabled,
    formData
}) {
    const [textareaInfos, setTextareaInfos] = useState(formData.infos ?? "");
    const [file, setFile] = useState(formData.file ?? null);
    const [inputPermis, setInputPermis] = useState(formData.permis ?? "");

    useEffect(() => {
        onSubmit({
            infos: textareaInfos,
            file,
            fileName: file?.name,
            permis: inputPermis
        });
        setDisabled(false);

    }, [textareaInfos, file, inputPermis]);

    useEffect(() => {
        if (formData.infos || formData.permis || formData.file) {
            setDisabled(false);
        }
    }, []);

    return (
        <div className=" step step-five">
            <div>
                <p className='question question-five'>Quels sont les détails de votre projet ?</p>
                <p className='explication'>Veuillez renseigner les détails de votre projet d’abri et nous fournir les éléments que vous jugerez nécessaires (photos, côtes, plan, schéma, permis).</p>
                <div className='inputs'>
                    <div className='input'>
                        <textarea
                            value={textareaInfos}
                            onChange={(e) => setTextareaInfos(e.target.value)}
                            placeholder='Informations concernant le projet'>
                        </textarea>
                    </div>
                    <input
                        type="file"
                        id="file-input"
                        className={formData.file != null ? 'active' : ''}
                        onChange={(e) => {
                            setFile(e.target.files[0])
                        }}>
                    </input>
                    <label className={formData.file != null ? 'active' : ''} htmlFor="file-input">{(formData.file != null ? 'Fichier ajouté' : 'Ajouter des pièces jointes')}</label>
                </div>
                <p className='question'>Avez-vous déjà fait une demande de permis de construire ?</p>
                <div className="inputs flex-column inputs-radios">
                    <div
                        className={"fake-input" + (inputPermis == "Oui" ? " active" : "")}
                        onClick={() => {
                            setInputPermis("Oui");
                        }}
                    >
                        <p>Oui</p>
                    </div>
                    <div
                        className={"fake-input" + (inputPermis == "Non" ? " active" : "")}
                        onClick={() => {
                            setInputPermis("Non");
                        }}
                    >
                        <p>Non</p>
                    </div>
                </div>
                {/* <p className='dont-know' onClick={() => setFile('Je ne souhaite pas donner de détails')}>Je ne souhaite pas donner de détails</p> */}
            </div>
        </div>
    );
}

export default StepFive;