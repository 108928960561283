import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import './Content.scss';

import slider1 from "../../assets/img/slider-1.jpg";
import slider2 from "../../assets/img/slider-2.jpg";
import slider3 from "../../assets/img/slider-3.jpg";
import logoSlider from "../../assets/img/logo.png";

const ContentSlider = () => {
	const SliderImg = [
        slider1,
        slider2,
        slider3,
    ];

    const handleClickScroll = () => {
        const element = document.getElementById('App');
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

	return (
		<div className="Slider">
            <img className='logo' src={logoSlider} />
			<h1>Déco Charpente<br /><span>Abris d'excellence</span></h1>
            <button className="button-primary" onClick={handleClickScroll}>Demander un devis</button>
			<Slider slide={SliderImg} />
		</div>
	);

	function Slider(props) {
		const slides = props.slide

		return (
			<div className='slider-holder'>
                <Swiper
                    pagination={{
                        dynamicBullets: true,
                    }}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    modules={[Autoplay, Pagination]}
                    spaceBetween={0}
                    slidesPerView={1}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                >
				{slides.map((slide) => (
					<SwiperSlide>
						<img src={slide} />
                    </SwiperSlide>
				))}
                </Swiper>
			</div>
		)
	}
}

export default ContentSlider;