import './Content.scss';

const ContentProducts = () => {
	const myProducts = [
		{
			img: 'https://www.deco-charpente.fr/wp-content/uploads/2024/02/Pool-house.jpg',
			link: 'https://www.deco-charpente.fr/pool-house-kiosque/'
		},
		{
			img: 'https://www.deco-charpente.fr/wp-content/uploads/2024/02/Abri-voiture.jpg',
			link: 'https://www.deco-charpente.fr/abri-voiture-bois/'
		},
		{
			img: 'https://www.deco-charpente.fr/wp-content/uploads/2024/02/Appentis.jpg',
			link: 'https://www.deco-charpente.fr/appentis-et-auvent/'
		},
		{
			img: 'https://www.deco-charpente.fr/wp-content/uploads/2024/02/Pergola.jpg',
			link: 'https://www.deco-charpente.fr/pergola-bois/'
		},
		{
			img: 'https://www.deco-charpente.fr/wp-content/uploads/2024/02/Espaces-co.jpg',
			link: 'https://www.deco-charpente.fr/espaces-collectifs/'
		},
		{
			img: 'https://www.deco-charpente.fr/wp-content/uploads/2024/02/Solutions-deco.jpg',
			link: 'https://www.deco-charpente.fr/solutions-deco/'
		},
	];

	return (
		<div className="Products">
			<h2>Depuis 25ans, spécialiste :</h2>
			<Product product={myProducts} />
		</div>
	);

	function Product(props) {
		const products = props.product

		return (
			<div className='product-holder'>
				{products.map((product) => (
                    <a href={product.link} target='_blank'>
                        <img src={product.img} />
                    </a>
				))}
			</div>
		)
	}
}

export default ContentProducts;