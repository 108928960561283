import "./Recap.scss";
import logoGarantie from "../../assets/img/garantie-10-ans-bistre.png";
import logoPefc from "../../assets/img/pefc-label-pefc.png";
import logoMade from "../../assets/img/madeinfrance.png";
import logoSavoir from "../../assets/img/savoir-faire-excellence.png";
import moment from "moment";

function Recap(props) {
    const {
        formData: { abri, abriType, pentes, hauteur, profondeur, largeur, mois, annee, pose, zipcode, city, adress, fileName, file, lastname, firstname, email, phone },
    } = props;

    return (
        <div className="card-recap">
            <p className="title-recap">Récapitulatif de votre demande</p>
            <div className="infos">
                <div className="column-infos">
                    <p className="info-name">
                        <strong>Abris : </strong>
                    </p>
                    {abri && <p className="answer-info">{abri}</p>}
                </div>
                <div className="column-infos column-flex">
                    <div className="flex-row">
                        <p className="info-name">
                            <strong>Type : </strong>
                        </p>
                        <p className="answer-info">
                            {abriType && <span>{abriType} </span>}
                        </p>
                    </div>
                    <div className="flex-row">
                        <p className="info-name"><strong>Pente :</strong></p>
                        <p className="answer-info">
                            {pentes && (
                                <span>
                                    {pentes} {pentes === "1" ? "pente" : (pentes !== "Je ne sais pas" ? "pentes" : "")}

                                </span>
                            )}
                        </p>
                    </div>

                </div>

                <div className="column-infos column-flex">
                    <p className="info-name">
                        <strong>Dimensions : </strong>
                    </p>
                    {hauteur === "Je ne sais pas" && largeur === " " && profondeur === " " ? (
                        <p className="answer-info">{hauteur}</p>
                    ) : (
                        <p className="answer-info dim">
                            {hauteur && <span>H{hauteur}, </span>}
                            {largeur && <span>L{largeur}, </span>}
                            {profondeur && <span>P{profondeur}</span>}
                        </p>
                    )}
                </div>

                <div className="column-infos column-flex">
                    <div>
                        <p className="info-name">
                            <strong>Date du projet : </strong>
                        </p>
                        {annee === "Je ne sais pas" ? (
                            <p className="answer-info">{annee}</p>
                        ) : (
                            <p className="answer-info t-capitalize">
                                {mois && <span>{moment(mois, "M").format("MMMM")} </span>}
                                {annee && <span>{annee}</span>}
                            </p>
                        )}
                    </div>
                    <div className="flex-row">
                        <p className="info-name pose">Pose :</p>
                        {pose === "Je ne sais pas" ? <p className="answer-info pose-answer">{pose}</p> : <p className="answer-info pose-answer">{pose}</p>}
                    </div>
                </div>

                {fileName && <div className="column-infos no-pin"> <p className="file-info answer-info">{fileName}</p></div>}

                <div className="column-infos column-flex">
                    <p className="info-name">
                        <strong>Localisation : </strong>
                    </p>
                    <p className="answer-info">
                        {adress && <span>{adress} </span>}
                        {zipcode && <span>{zipcode} </span>}
                        {city && <span>{city}</span>}
                    </p>
                </div>
                <div className="column-infos column-flex">
                    <p className="info-name">
                        <strong>Nom : </strong>
                    </p>
                    <p className="answer-info">
                        {firstname && <span>{firstname} </span>}
                        {lastname && <span>{lastname}</span>}
                    </p>
                </div>
                <div className="column-infos column-flex">
                    <p className="info-name">
                        <strong>Adresse mail : </strong>
                    </p>
                    {email && <p className="answer-info">{email}</p>}
                </div>
                <div className="column-infos">
                    <p className="info-name">
                        <strong>Tel : </strong>
                    </p>
                    {phone && <p className="answer-info">{phone}</p>}
                </div>
            </div>
            {/* <div className="logos">
                <img className="logo-garantie" src={logoGarantie} alt="logo-garantie"></img>
                <img className="logo-pefc" src={logoPefc} alt="logo-pefc"></img>
                <img className="logo-made" src={logoMade} alt="logo-made-in-france"></img>
                <img className="logo-made" src={logoSavoir} alt="logo-savoir-faire"></img>
            </div> */}
        </div>
    );
}

export default Recap;
