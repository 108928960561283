import appentis from "../../assets/img/appentis.png";
import pergolas from "../../assets/img/pergolas.png";
import poolHouse from "../../assets/img/poolhouse.png";
import abriVoiture from "../../assets/img/abri-voiture.png";
import abriCollectif from "../../assets/img/espace-collectif.png";
import solutionDeco from "../../assets/img/solution-deco.png";
import adosse from "../../assets/img/adosse.png";

export const abris = [
    {
        label: "Appentis & Auvent",
        image: appentis,
        types: [
            {
                label: "Adossé",
                image: adosse,
            },
            {
                label: "Autoporté",
                image: pergolas,
            },
        ],
    },
    {
        label: "Pergola",
        image: pergolas,
        types: [
            {
                label: "Adossée",
                image: adosse,
            },
            {
                label: "Autoportée",
                image: pergolas,
            },
        ],
    },
    {
        label: "Pool house",
        image: poolHouse,
        types: [
            {
                label: "Adossé",
                image: adosse,
            },
            {
                label: "Autoporté",
                image: pergolas,
            },
        ],
    },
    {
        label: "Abri voiture",
        image: abriVoiture,
        types: [
            {
                label: "Adossé",
                image: adosse,
            },
            {
                label: "Autoporté",
                image: pergolas,
            },
        ],
    },
    {
        label: "Abri collectif",
        image: abriCollectif,
        types: [
            {
                label: "Adossé",
                image: adosse,
            },
            {
                label: "Autoporté",
                image: pergolas,
            },
        ],
    },
    {
        label: "Solution décorative",
        image: solutionDeco,
    },
];
