import "./Begin.scss";

function Begin({ handleNextStep }) {
    return (
        <div className="step-final begin">
            <div>
                <h2 className="title-fin">Devis Déco Charpente</h2>
                <a href="/" className="back" onClick={handleNextStep}>
                    Commencer
                </a>
            </div>
        </div>
    );
}

export default Begin;
