import "./Step.scss";
import { useEffect, useState } from "react";

function StepTwo({ onSubmit, handleNextStep, formData, setDisabled, abris }) {
    const [slopesSelected, setSlopesSelected] = useState(formData.pentes ?? "");

    useEffect(() => {
        if (formData.abriType && formData.pentes) {
            setDisabled(false);
        }
    }, [formData]);

    const abri = abris.find((element) => formData.abri == element.label);

    if (!abri?.types) {
        handleNextStep();
        return;
    }

    const labelOfSlopes = [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "+ de 4", value: "+ de 4" },
    ];

    const handleClick = (abriType) => {
        onSubmit({ abriType });
    };

    const handleSlopeClick = (value) => {
        setSlopesSelected(value);
        onSubmit({ pentes: value });
    };

    return (
        <div className=" step step-two">
            <div>
                <p className="question question-two">Quel type d'abri recherchez-vous ?</p>
                <div className="choices">
                    {abri.types &&
                        abri.types.map(({ label, image }, index) => (
                            <div key={index} className={"card " + (formData.abriType === label ? "selected" : "")} onClick={() => handleClick(label)}>
                                <div className="contour-img">
                                    <img src={image} alt="abri-appentis"></img>
                                </div>
                                <p>{label}</p>
                            </div>
                        ))}
                </div>
                <p className={"dont-know" + (formData.abriType == "Je ne sais pas" ? " active" : "")} onClick={() => handleClick("Je ne sais pas")}>
                    Je ne sais pas
                </p>
            </div>
            <div>
                <p className="question question-pentes">Combien de pentes souhaitez-vous ?</p>
                <div className="range-item">
                    <ul className="list-inline list-unstyled">
                        {labelOfSlopes.map(({ label, value }, index) => (
                            <li key={index} onClick={() => handleSlopeClick(value)} className={"list-inline-item" + (value == slopesSelected ? " active" : "")}>
                                <span>{label}</span>
                            </li>
                        ))}
                    </ul>
                    {formData.pentes != "Je ne sais pas" && (
                        <p className="slopes-wish">
                            Je souhaite {slopesSelected == "" ? "*" : slopesSelected} {slopesSelected == "1" ? "pente" : "pentes"}
                        </p>
                    )}
                </div>
                <p className={"dont-know" + (formData.pentes == "Je ne sais pas" ? " active" : "")} onClick={() => handleSlopeClick("Je ne sais pas")}>
                    Je ne sais pas
                </p>
            </div>
        </div>
    );
}

export default StepTwo;

/*
function StepTwo({ onSubmit, handleNextStep, formData, setDisabled }) {
    const [slopesSelected, setSlopesSelected] = useState('');

    const labelOfSlopes = [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '+ de 4', value: '+ de 4' },
    ];

    const typesAbri = [
        { name: "Adossé", src: appentis },
        { name: "Autoporté", src: pergolas },
    ];

    const handleClick = (abriType) => {
        onSubmit({ abriType });
        setDisabled(false);
    };
    return (
        <div className=" step step-two">
            <div>
                <p className="question question-two">Quel type d'abri recherchez-vous ?</p>
                <div className="choices">
                    {typesAbri.map(({ name, src }, index) => (
                        <div key={index} className={"card " + (formData.abriType === name ? "selected" : "")} onClick={() => handleClick(name)}>
                            <div className="contour-img">
                                <img src={src} alt="abri-appentis"></img>
                            </div>
                            <p>{name}</p>
                        </div>
                    ))}
                </div>
                <p className="dont-know" onClick={handleNextStep}>
                    Je ne sais pas
                </p>
            </div>
            <div>
                <p className="question question-two">Combien de pentes souhaitez-vous ?</p>
                <div className="range-item">
                    <ul className="list-inline list-unstyled">
                        {labelOfSlopes.map(({ label, value }, index) => (
                            <li onClick={() => setSlopesSelected(value)} className={"list-inline-item" + (value == slopesSelected ? ' active' : '')}>
                                <span>{label}</span>
                            </li>
                        ))}
                    </ul>
                    <p>Je souhaite {slopesSelected} {slopesSelected == '1' ? 'pente' : 'pentes'}</p>
                </div>
                <p className="dont-know" onClick={handleNextStep}>
                    Je ne sais pas
                </p>
            </div>
        </div >
    );
}
*/
