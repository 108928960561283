import { useEffect, useState } from "react";
import moment from "moment";
import "./Step.scss";

function StepFour({ onSubmit, setDisabled, formData }) {
    const [inputMonth, setInputMonth] = useState(formData.mois ?? "");
    const [inputYear, setInputYear] = useState(formData.annee ?? "");
    const [inputPose, setInputPose] = useState(formData.pose ?? "");

    useEffect(() => {
        if (!inputMonth && !inputYear && !inputPose) return;
        onSubmit({
            mois: inputMonth,
            annee: inputYear,
            pose: inputPose,
        });
        if (inputMonth && inputYear && inputPose) {
            setDisabled(false);
        }
    }, [inputMonth, inputYear, inputPose]);

    useEffect(() => {
        if (formData.mois || formData.annee || formData.pose) {
            setDisabled(false);
        }
    }, []);

    const handleInputChange = (value) => {
        if (value !== "Je ne sais pas" && inputYear === "Je ne sais pas") {
            setDisabled(true);
            setInputYear("");
            setInputMonth("");
        }
    };

    const handleMonthChange = (value) => {
        handleInputChange(value);
        setInputMonth(value);

    };

    const handleYearChange = (value) => {
        handleInputChange(value);
        setInputYear(value);
    };

    return (
        <div className="step step-four">
            <div className="first-question-step-four">
                <p className="question question-four">Quand souhaitez-vous profiter de votre abri ?</p>
                <div className="inputs selects flex-column">
                    <div className="input">
                        <select className="t-capitalize" id="mois" value={inputMonth} onChange={(e) => handleMonthChange(e.target.value)}>
                            <option value="">
                                Mois
                            </option>
                            {Array.from({ length: 12 }, (value, index) => index + 1).map((month, index) => (
                                <option key={index} value={month}>
                                    {moment(month, "M").format("MMMM")}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="input">
                        <select id="year" value={inputYear} onChange={(e) => handleYearChange(e.target.value)}>
                            <option value="">
                                Année
                            </option>
                            {Array.from({ length: 6 }, (value, index) => index + parseInt(moment().format("YYYY"))).map((year, index) => (
                                <option key={index} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <p
                    className={"dont-know" + (formData.mois == "Je ne sais pas" && formData.annee == "Je ne sais pas" ? " active" : "")}
                    onClick={() => {
                        setInputYear("Je ne sais pas");
                        setInputMonth("Je ne sais pas");
                    }}
                >
                    Je ne sais pas
                </p>
            </div>
            <div className="second-question-step-four">
                <p className="question">Souhaitez-vous la pose de votre abri par nos équipes ?</p>
                <div className="inputs flex-column inputs-radios">
                    <div
                        className={"fake-input" + (inputPose == "Deco Charpente" ? " active" : "")}
                        onClick={() => {
                            setInputPose("Deco Charpente");
                        }}
                    >
                        <p>Oui</p>
                    </div>
                    <div
                        className={"fake-input" + (inputPose == "Non" ? " active" : "")}
                        onClick={() => {
                            setInputPose("Non");
                        }}
                    >
                        <p>Non</p>
                    </div>
                </div>
                <p className={"dont-know" + (formData.pose == "Je ne sais pas" ? " active" : "")} onClick={() => setInputPose("Je ne sais pas")}>
                    Je ne sais pas
                </p>
            </div>
        </div>
    );
}

export default StepFour;
