import "./Step.scss";
import dimensions from "../../assets/img/dimensions.png";
import arrowHeight from "../../assets/img/fleche-250.svg";
import arrowWidth from "../../assets/img/fleche-450.svg";
import arrowDepth from "../../assets/img/fleche-300.svg";
import { ImageProvider } from "../../providers/ImageProvider.js";
import { useEffect, useState } from "react";

function StepThree({ onSubmit, setDisabled, formData }) {
    const [inputHeight, setInputHeight] = useState(formData.hauteur ?? "");
    const [inputWidth, setInputWidth] = useState(formData.largeur ?? "");
    const [inputDepth, setInputDepth] = useState(formData.profondeur ?? "");

    useEffect(() => {
        onSubmit({
            hauteur: inputHeight,
            largeur: inputWidth,
            profondeur: inputDepth,
        });
        const areInputsFilled = (inputHeight && inputWidth && inputDepth);
        setDisabled(!areInputsFilled);

    }, [inputHeight, inputWidth, inputDepth]);

    useEffect(() => {
        if (formData.hauteur || formData.largeur || formData.profondeur) {
            setDisabled(false);
        }
    }, []);

    const handleInputChange = (value) => {
        if (value !== "" && (inputHeight === "Je ne sais pas" || inputWidth === " " || inputDepth === " ")) {
            setDisabled(true);
            setInputHeight("");
            setInputWidth("");
            setInputDepth("");
        }
    };

    const handleHeightChange = (value) => {
        handleInputChange(value);
        setInputHeight(value);
    };

    const handleWidthChange = (value) => {
        handleInputChange(value);
        setInputWidth(value);
    };

    const handleDepthChange = (value) => {
        handleInputChange(value);
        setInputDepth(value);
    };

    return (
        <div className="step step-three">
            <div>
                <p className="question question-three">Quelles sont les dimensions de votre abri ?</p>

                {formData.abri == "Je ne sais pas" ? (
                    ""
                ) : (
                    <p className="rappel">
                        {formData.abri} {formData.abriType == "Je ne sais pas" ? "" : formData.abriType}{" "}

                        {formData.pentes !== "Je ne sais pas" && formData.pentes && (
                            <span>
                                {" "}
                                à {formData.pentes} {formData.pentes === "1" ? "pente" : "pentes"}
                            </span>
                        )}

                    </p>
                )}

                <div className="schema">
                    {/* {ImageProvider(formData.abri, formData.abriType, formData.pentes)} */}
                    <img src={dimensions} alt="dimensions"></img>
                    <div>
                        <img className="arrow arrow-depth" src={arrowDepth} alt="fleche-depth"></img>
                        <p className="value value-depth"> {inputDepth === " " ? "***" : (inputDepth === "" ? "***" : inputDepth)} cm</p>
                    </div>
                    <div>
                        <img className="arrow arrow-width" src={arrowWidth} alt="fleche-width"></img>
                        <p className="value value-width"> {inputWidth === " " ? "***" : (inputWidth === "" ? "***" : inputWidth)} cm</p>
                    </div>
                    <div>
                        <img className="arrow arrow-height" src={arrowHeight} alt="fleche-height"></img>
                        <p className="value value-height"> {inputHeight === "Je ne sais pas" ? "***" : (inputHeight === "" ? "***" : inputHeight)} cm</p>
                    </div>
                </div>
                <div className="inputs flex-column inputs-size">
                    <div className="input">
                        <label htmlFor="depth">Profondeur :</label>
                        <input type="number" id="depth" name="depth" value={inputDepth} onChange={(e) => handleDepthChange(e.target.value)} placeholder="cm"></input>
                    </div>
                    <div className="input">
                        <label htmlFor="width">Largeur :</label>
                        <input type="number" id="width" name="width" value={inputWidth} onChange={(e) => handleWidthChange(e.target.value)} placeholder="cm"></input>
                    </div>
                    <div className="input">
                        <label htmlFor="height">Hauteur :</label>
                        <input type="number" id="height" name="height" value={inputHeight} onChange={(e) => handleHeightChange(e.target.value)} placeholder="cm"></input>
                    </div>
                </div>
                <p className={"dont-know" + (formData.hauteur == "Je ne sais pas" && formData.largeur == " " && formData.profondeur == " " ? " active" : "")} onClick={() => { setInputHeight("Je ne sais pas"); setInputWidth(" "); setInputDepth(" "); }}>
                    Je ne sais pas
                </p>
            </div>
        </div>
    );
}

export default StepThree;
