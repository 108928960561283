import { useEffect, useState } from "react";
import "./Step.scss";

function StepSix({ onSubmit, setSend, formData }) {
    const [inputLastname, setInputLastname] = useState(formData.lastname ?? "");
    const [inputFirstname, setInputFirstname] = useState(formData.firstname ?? "");
    const [inputEmail, setInputEmail] = useState(formData.email ?? "");
    const [inputPhone, setInputPhone] = useState(formData.phone ?? "");
    const [inputZipcode, setInputZipcode] = useState(formData.zipcode ?? "");
    const [inputCity, setInputCity] = useState(formData.city ?? "");
    const [inputAdress, setInputAdress] = useState(formData.adress ?? "");

    useEffect(() => {
        if (
            !inputLastname &&
            !inputFirstname &&
            !inputEmail &&
            !inputPhone &&
            !inputAdress &&
            !inputZipcode &&
            !inputCity &&
            !validateEmail(inputEmail) &&
            !validatePhone(inputPhone))
            return;
        onSubmit({
            lastname: inputLastname,
            firstname: inputFirstname,
            email: inputEmail,
            phone: inputPhone,
            adress: inputAdress,
            zipcode: inputZipcode,
            city: inputCity,
        });
        if (inputLastname && inputFirstname && inputEmail && inputPhone && inputAdress && inputZipcode && inputCity) { setSend(false); }
    }, [
        inputLastname,
        inputFirstname,
        inputEmail,
        inputPhone,
        inputAdress,
        inputZipcode,
        inputCity
    ]);

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const validatePhone = (phone) => {
        return phone.match(
            /^(0|\+33|0033)[1-9]([-. ]?[0-9]{2}){4}$/
        );
    };

    return (
        <div className=" step step-six">
            <div>
                <p className="question question-six">Quelles sont vos coordonnées de contact ?</p>
                <div className="inputs">
                    <div className="flex-column">
                        <div className="input">
                            <label htmlFor="lastname">Nom</label>
                            <input
                                type="text"
                                id="lastname"
                                name="lastname"
                                value={inputLastname}
                                required
                                onChange={(e) => setInputLastname(e.target.value)}
                                placeholder="Nom">
                            </input>
                        </div>
                        <div className="input">
                            <label htmlFor="firstname">Prénom</label>
                            <input
                                type="text"
                                id="firstname"
                                name="firstname"
                                value={inputFirstname}
                                required
                                onChange={(e) => setInputFirstname(e.target.value)}
                                placeholder="Prénom">
                            </input>
                        </div>
                    </div>

                    <div className="input">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={inputEmail}
                            onChange={(e) => setInputEmail(e.target.value)}
                            placeholder="Email">
                        </input>
                    </div>
                    <div className="input">
                        <label htmlFor="phone">Téléphone</label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={inputPhone}
                            onChange={(e) => setInputPhone(e.target.value)}
                            placeholder="Téléphone">
                        </input>
                    </div>
                    <div className="input">
                        <label htmlFor="adress">Adresse</label>
                        <input
                            type="text"
                            id="adress"
                            name="adress"
                            value={inputAdress}
                            onChange={(e) => setInputAdress(e.target.value)}
                            placeholder="Adresse">
                        </input>
                    </div>
                    <div className="flex-column">
                        <div className="input">
                            <label htmlFor="zipcode">Code Postal</label>
                            <input
                                type="text"
                                id="zipcode"
                                name="zipcode"
                                value={inputZipcode}
                                onChange={(e) => setInputZipcode(e.target.value)}
                                placeholder="Code postal">
                            </input>
                        </div>
                        <div className="input">
                            <label htmlFor="city">Ville</label>
                            <input
                                type="text"
                                id="city"
                                name="city"
                                value={inputCity}
                                onChange={(e) => setInputCity(e.target.value)}
                                placeholder="Ville">
                            </input>
                        </div>
                    </div>
                    <a href="/mentions-legales/" target="_blank">
                        Consulter notre RGPD
                    </a>
                </div>
            </div>
        </div>
    );
}

export default StepSix;
