import { useEffect, useState } from "react";
import Form from "../Form/Form";
import Final from "../Final/Final";
import ContentSlider from "../Content/ContentSlider";
import ContentProducts from "../Content/ContentProducts";
import ContentProcess from "../Content/ContentProcess";
import ContentGalery from "../Content/ContentGalery";
import moment from "moment";
import axios from "axios";
import logoGarantie from "../../assets/img/garantie-10-ans-bistre.png";
import logoPefc from "../../assets/img/pefc-label-pefc.png";
import logoMade from "../../assets/img/madeinfrance.png";
import logoSavoir from "../../assets/img/macaron-excellence.png";
import { abris as data } from "../Form/abris";
import Swal from "sweetalert2";
import "moment/locale/fr";
import "./App.scss";

function App() {
    const [formData, setFormData] = useState({});
    const [currentStep, setCurrentStep] = useState(1);
    const [accessibleStep, setAccessibleStep] = useState(1);
    const [disabled, setDisabled] = useState(true);
    const [send, setSend] = useState(true);
    const [formSubmit, setFormSubmit] = useState(false);
    const [abris, setAbris] = useState(data);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        moment.locale("fr");

    }, []);

    useEffect(() => {
        if (currentStep > accessibleStep) setAccessibleStep(currentStep);
    }, [currentStep]);

    const abri = abris.find((element) => formData.abri == element.label);

    const handleStepSubmit = (data) => {
        setFormData((prevFormData) => ({ ...prevFormData, ...data }));
    };

    const handleNextStep = () => {
        setCurrentStep(currentStep + 1);
        setDisabled(true);
        if (currentStep == 1 && formData.abri == "Solution décorative" && formData.pentes) {
            formData.abriType = "";
            formData.pentes = "";
        }

    };

    const handlePrevStep = () => {
        if (!abri?.types && currentStep == 3) {
            setCurrentStep(currentStep - 2);
            return;
        }

        setCurrentStep(currentStep - 1);
    };

    const handleGoToStep = (stepNumber) => {
        setCurrentStep(() => {
            if (stepNumber <= accessibleStep) {
                return stepNumber;
            } else {
                return accessibleStep;
            }
        });

        if (currentStep == 1 && formData.abri == "Solution décorative" && formData.pentes) {
            formData.abriType = "";
            formData.pentes = "";
        }
    };

    const handleSubmit = () => {
        setLoading(true);
        axios.post('https://deco-charpente-api.getup.agency/app/quotation/add', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }
        ).then(res => {
            setLoading(false);
            if (res.status == 200) {
                setFormSubmit(true);
            } else {
                Swal.fire({
                    icon: "error",
                    iconColor: "#c03c2f",
                    title: "Une erreur est survenue",
                    confirmButtonText: "Retour au formulaire",
                    confirmButtonColor: "#322b29",
                    color: "#322b29",
                    footer: '<a class="link-popup" href="#">Nous contacter</a>'
                });
            }
        })
    }

    return (
        <div className="main">
            <div className="ContentSlider content">
                <ContentSlider />
            </div>
            <div className="ContentProducts content">
                <ContentProducts />
            </div>
            <div className="ContentProcess content">
                <ContentProcess />
            </div>
            <div className="ContentGalery content">
                <ContentGalery />
            </div>
            <div className="App" id="App">

                <div className="header-form">
                    <div className="logos">
                        <img className="logo-garantie" src={logoGarantie} alt="logo-garantie"></img>
                        <img className="logo-pefc" src={logoPefc} alt="logo-pefc"></img>
                    </div>
                    <div>
                        <h1 className="title">Le devis de votre abri Déco Charpente gratuit</h1>
                        <p className="intro">
                            Afin que notre équipe puisse répondre au mieux à votre demande et vous proposer une étude personnalisée pour votre projet de menuiseries, merci de remplir
                            le formulaire ci-contre. <strong>Votre demande de devis menuiseries est gratuite et sans engagement.</strong>
                        </p>
                    </div>
                    <div className="logos">
                        <img className="logo-made" src={logoMade} alt="logo-made-in-france"></img>
                        <img className="logo-made" src={logoSavoir} alt="logo-savoir-faire"></img>
                    </div>

                </div>
                {!formSubmit ? (
                    <><Form
                        handleStepSubmit={handleStepSubmit}
                        currentStep={currentStep}
                        accessibleStep={accessibleStep}
                        formData={formData}
                        handleGoToStep={handleGoToStep}
                        handleNextStep={handleNextStep}
                        setDisabled={setDisabled}
                        setSend={setSend}
                        loading={loading} />

                        <div className="container-buttons">
                            <div className="link-prev">
                                <button className="link" onClick={() => handlePrevStep(currentStep)}>
                                    Etape précédente
                                </button>
                            </div>
                            <div className="button-center">
                                <button disabled={disabled} className="button-primary" onClick={handleNextStep}>
                                    Confirmer et passer à l’étape suivante
                                </button>
                            </div>
                            <div className="button-end">
                                <button disabled={send} className="button-submit" onClick={handleSubmit}>
                                    Envoyer
                                </button>
                            </div>
                        </div></>
                ) : (
                    <Final />
                )}

            </div>
        </div>
    );
}

export default App;
