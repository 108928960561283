import "./Final.scss";
import iconCheck from "../../assets/img/icon-check.png";

function Final() {
    return (
        <div className="step-final">
            <div>
                <img className="icon-check" src={iconCheck} alt="icon-check" />
                <h2 className="title-fin">Demande envoyée !</h2>
                <div className="sentence">
                    <p>Nous traiterons vos informations dans les plus brefs délais et reviendrons vers vous prochainement pour concevoir votre projet</p>
                </div>
                <a href="/" className="back">
                    Retourner à l'accueil
                </a>
            </div>
        </div>
    );
}

export default Final;
