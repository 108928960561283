import Recap from "../Recap/Recap";
import Final from "../Final/Final";
import StepFive from "../Steps/StepFive";
import StepSix from "../Steps/StepSix";
import StepFour from "../Steps/StepFour";
import StepOne from "../Steps/StepOne";
import StepThree from "../Steps/StepThree";
import StepTwo from "../Steps/StepTwo";
import { Oval } from 'react-loader-spinner';
import "./Form.scss";
import { useState } from "react";
import { abris as data } from "./abris";
import Begin from "../Begin/Begin";

function Form({ currentStep, accessibleStep, handleStepSubmit, formData, handleGoToStep, handleNextStep, setDisabled, setSend, loading }) {
    const [abris, setAbris] = useState(data);
    const steps = [
        {
            nameClass: "step-number step-number-one",
            name: "Choix de l’abri"
        },
        {
            nameClass: "step-number step-number-two",
            name: "Type d’abri"
        },
        {
            nameClass: "step-number step-number-three",
            name: "Dimensions de l'abri"
        },
        {
            nameClass: "step-number step-number-four",
            name: "Date et pose"
        },
        {
            nameClass: "step-number step-number-five",
            name: "Details du projet",
        },
        {
            nameClass: "step-number step-number-six",
            name: "Coordonnées"
        },
    ];

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return <StepOne
                    onSubmit={handleStepSubmit}
                    formData={formData}
                    setDisabled={setDisabled}
                    abris={abris} />;
            case 2:
                return <StepTwo
                    onSubmit={handleStepSubmit}
                    handleNextStep={handleNextStep}
                    formData={formData}
                    setDisabled={setDisabled}
                    abris={abris} />;
            case 3:
                return <StepThree
                    onSubmit={handleStepSubmit}
                    formData={formData}
                    setDisabled={setDisabled} />;
            case 4:
                return <StepFour
                    onSubmit={handleStepSubmit}
                    formData={formData}
                    setDisabled={setDisabled} />;
            case 5:
                return <StepFive
                    onSubmit={handleStepSubmit}
                    formData={formData}
                    setDisabled={setDisabled} />;
            case 6:
                return <StepSix
                    onSubmit={handleStepSubmit}
                    formData={formData}
                    setSend={setSend} />;
            default:
                return <Begin handleNextStep={handleNextStep} />;
        }
    };
    return (
        <div className="form">

            <div className="number-steps">
                <p className="title-step-number">En {steps.length} étapes</p>
                <div className="step-by-step">
                    {steps.map(({ nameClass, name }, index) => (
                        <p
                            key={index}
                            className={`${nameClass} ${accessibleStep >= (index + 1) ? 'active' : ''} ${currentStep === (index + 1) ? 'current-item' : ''}`}
                            onClick={() => handleGoToStep(index + 1)}>
                            {name}
                        </p>
                    ))}
                </div>
            </div>
            <div className="steps">
                {renderStep()}
                {loading &&
                    <div className="loader">
                        <Oval
                            visible={true}
                            height="80"
                            width="80"
                            color="#fff"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            secondaryColor="#fff"
                            strokeWidth='3'
                            strokeWidthSecondary='3'
                        />
                    </div>
                }
            </div>
            <div className="recap">
                <Recap formData={formData} />
            </div>
        </div>
    );
}

export default Form;
