import './Content.scss';

const ContentGalery = () => {
	const myGalery = [
		'https://www.deco-charpente.fr/wp-content/uploads/2023/03/Abri-voiture-grande-envergure.jpg',
		'https://www.deco-charpente.fr/wp-content/uploads/2021/02/Abri-voiture-4-pans-avec-garde-corps-croix-de-Saint-Andre-600x450.jpg',
		'https://www.deco-charpente.fr/wp-content/uploads/2023/11/Abri-voiture-autoporte.jpg',
		'https://www.deco-charpente.fr/wp-content/uploads/2023/08/abri-voiture-adosse.jpg',
		'https://www.deco-charpente.fr/wp-content/uploads/2020/08/rive-en-porte-%C3%A0-faux-pointe-de-diamant-sur-poin%C3%A7on-600x450.jpg',
		'https://www.deco-charpente.fr/wp-content/uploads/2022/06/Abri-voiture-C2-2-compressed-600x450.jpg',
		'https://www.deco-charpente.fr/wp-content/uploads/2021/08/Carport-3-voitures.png',
		'https://www.deco-charpente.fr/wp-content/uploads/2021/02/Abri-tunnel-600x450.jpg',
	];

	return (
		<div className="Galery">
			<h2>Une galerie de +1500 réalisations</h2>
			<a href='https://www.deco-charpente.fr/galerie/' className='button-primary' target='_blank'>Voir la galerie</a>
			<Galery galery={myGalery} />
		</div>
	);

	function Galery(props) {
		const galeryImgs = props.galery

		return (
			<div className='galery-holder'>
				{galeryImgs.map((galeryImg) => (
					<div className='galery-item'>
						<img src={galeryImg} />
					</div>
				))}
			</div>
		)
	}
}

export default ContentGalery;