import './Content.scss';

const ContentProcess = () => {
	const allProcess = [
		{
			icon: 'https://www.deco-charpente.fr/wp-content/uploads/2020/07/ico-france.png',
			title: 'Fabrication & Bois Français',
			desc: 'Tous nos abris sont fabriqués en France, dans les ateliers Déco Charpente du Var 83. Nous disposons en exclusivité d’une certification et traçabilité sur chaque bois utilisé.',
		},
		{
			icon: 'https://www.deco-charpente.fr/wp-content/uploads/2020/07/ico-france.png',
			title: 'Fabrication & Bois Français',
			desc: 'Tous nos abris sont fabriqués en France, dans les ateliers Déco Charpente du Var 83. Nous disposons en exclusivité d’une certification et traçabilité sur chaque bois utilisé.',
		},
		{
			icon: 'https://www.deco-charpente.fr/wp-content/uploads/2020/07/ico-france.png',
			title: 'Fabrication & Bois Français',
			desc: 'Tous nos abris sont fabriqués en France, dans les ateliers Déco Charpente du Var 83. Nous disposons en exclusivité d’une certification et traçabilité sur chaque bois utilisé.',
		},
		{
			icon: 'https://www.deco-charpente.fr/wp-content/uploads/2020/07/ico-france.png',
			title: 'Fabrication & Bois Français',
			desc: 'Tous nos abris sont fabriqués en France, dans les ateliers Déco Charpente du Var 83. Nous disposons en exclusivité d’une certification et traçabilité sur chaque bois utilisé.',
		},
	];

	return (
		<div className="Process">
			<h2>Un processus minutieux</h2>
			<Process item={allProcess} />
		</div>
	);

	function Process(props) {
		const process = props.item

		return (
			<div className='process-holder'>
				{process.map((itProcess) => (
					<div className='process-item'>
						<h3>
							<img src={itProcess.icon} />
							<span>{itProcess.title}</span>
						</h3>
						<p>{itProcess.desc}</p>
					</div>
				))}
			</div>
		)
	}
}

export default ContentProcess;