import { useEffect } from "react";
import "./Step.scss";

function StepOne({
    onSubmit,
    formData,
    setDisabled,
    abris
}) {

    useEffect(() => {
        if (formData.abri) {
            setDisabled(false);
        }
    }, []);

    const handleClick = (abri) => {
        onSubmit({ abri });
        setDisabled(false);
    };

    return (
        <div className="step step-one">
            <div>
                <p className="question question-one">Quel abri souhaitez-vous installer ?</p>
                <div className="choices">
                    {abris && abris.map(({ label, image }, index) => (
                        <div
                            key={index}
                            className={"card " + (formData.abri === label ? "selected" : "")}
                            onClick={() => handleClick(label)}>
                            <div className="contour-img">
                                <img src={image} alt="abri-appentis"></img>
                            </div>
                            <p>{label}</p>
                        </div>
                    ))}
                </div>
                <p className={"dont-know" + (formData.abri == "Je ne sais pas" ? " active" : "")} onClick={() => handleClick('Je ne sais pas')}>
                    Je ne sais pas
                </p>
            </div>
        </div>
    );
}

export default StepOne;

/*
function StepOne({ onSubmit, handleNextStep, formData, setDisabled }) {


    const differentsAbri = [
        { name: "Appentis & Auvent", src: appentis },
        { name: "Pergola", src: pergolas },
        { name: "Pool House", src: poolHouse },
        { name: "Abri Voiture", src: abriVoiture },
        { name: "Abri collectif", src: abriCollectif },
        { name: "Solution décorative", src: solutionDeco },
    ];

    const handleClick = (abri) => {
        onSubmit({ abri });
        setDisabled(false);
    };

    return (
        <div className="step step-one">
            <div>
                <p className="question question-one">Quel abri souhaitez-vous installer?</p>
                <div className="choices">
                    {differentsAbri.map(({ name, src }, index) => (
                        <div key={index} className={"card " + (formData.abri === name ? "selected" : "")} onClick={() => handleClick(name)}>
                            <div className="contour-img">
                                <img src={src} alt="abri-appentis"></img>
                            </div>
                            <p>{name}</p>
                        </div>
                    ))}
                </div>
                <p className="dont-know" onClick={handleNextStep}>
                    Je ne sais pas
                </p>
            </div>
        </div>
    );
}
*/